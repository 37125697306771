<template>
  <section id="section2" class="artist-list">
    <h2 class="center title">アーティスト</h2>
    <div class="container center pc">
      <template v-for="artist in pcArtists" :key="artist.id">
        <div class="row">
          <template v-for="i in artist" :key="i.id">
            <div class="col span-4 artist">
              <img :src="i.img" alt="アーティスト" />
              <div class="artist-text">
                <h3>{{ i.name }}</h3>
                <p>{{ i.job }}</p>
                <p v-html="i.content"></p>
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>

    <div class="sp">
      <p class="sp-artist-text">詳細は画像をタッチしてください</p>
      <template v-for="artist in spArtists" :key="artist.id">
        <div class="sp-flex">
          <template v-for="i in artist" :key="i.id">
            <div class="sp-artist">
              <input type="checkbox" :id="i.num" />
              <label id="popup" :for="i.num" class="overlay">
                <label class="popWindow">
                  <label class="close" :for="i.num">×</label
                  ><img
                    itemprop="image"
                    loading="lazy"
                    :src="i.img"
                    alt="アーティスト"
                    width="300"
                    height="200"
                  />
                  <h3>{{ i.name }}</h3>
                  <p>{{ i.job }}</p>
                  <p v-html="i.content"></p>
                </label>
              </label>
              <div class="c">
                <label :for="i.num">
                  <img
                    itemprop="image"
                    loading="lazy"
                    :src="i.img"
                    alt=""
                    width="300"
                    height="200"
                  />
                  <p class="artist-name">{{ i.name }}</p>
                  <p>{{ i.job }}</p>
                </label>
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { ref, computed } from "vue";
export default {
  name: "ArtistList",
  setup() {
    const artists = ref([
      {
        num: "pop-up1",
        img: require("@/assets/img/artist01.webp"),
        name: "浅川奏瑛",
        job: "ダンス",
        content:
          "埼玉県越谷市出身。ダンサー、振付家。<br />尚美学園大学芸術情報学部舞台表現学科ダンスコース卒業。三輪亜希子、清水フミヒトに師事。<br />光や闇、生と死、戦争と平和といった様々な境界の中に揺らぐ輪郭のない存在に着目し、過去と現在を繋げ現在と未来を繋げること、そして平和への祈りを核とした作品を創作している。ヨコハマダンスコレクション2021-DEC 最優秀新人賞、アーキタンツ・アーティスト・サポート賞受賞。<br />SAI Dance Festival2023ソロ部門審査員賞受賞。クロスプレイ東松山2023年度滞在アーティスト。",
      },
      {
        num: "pop-up2",
        img: require("@/assets/img/artist02.webp"),
        name: "植川 縁",
        job: "音楽",
        content:
          "フランス国立リヨン地方音楽院を審査員満場一致の1等賞で卒業後、アムステルダム音楽院にて修士課程修了。ベルギー政府給費奨学生としてゲント音楽院現代音楽上級修士課程修了。ベルギーの現代音楽アンサンブルictusとspectraのアカデミーに所属（2012-2014)。日本ジュニア管打楽器コンクール第1位、キエリインターナショナルコンペティションファイナリスト。クラシック・現代音楽の演奏活動と並行し、即興演奏家としてワークショップやライブ出演を行っている。国内外のガムラン演奏会にサックス奏者／ガムラン奏者として出演。オランダのガムランアンサンブルWidosariメンバー。日本音楽即興学会会員。",
      },
      {
        num: "pop-up3",
        img: require("@/assets/img/artist03.webp"),
        name: "オーパ♪キャマラード合奏団",
        job: "音楽",
        content:
          "元NHKうたのおねえさんと実力派楽器のおねえさんたちの愉快なコンサート。<br />童謡からクラシック、世界の名曲まで、楽器体験あり！手遊びあり！アクロバット演奏あり！<br />大人から子どもまで楽しめるプログラムは工夫満載♪",
      },
      {
        num: "pop-up4",
        img: require("@/assets/img/artist04.webp"),
        name: "カナキティ",
        job: "舞踏",
        content:
          "舞踏家。青山学院女子短期大学芸術学科卒業。在学中絵画の授業で舞踏に出逢う。自身の表現を探求すべく、師を持たず独自に踊り始める。<br />北欧・ヨーロッパを始め国内外の芸術祭に招聘される一方、エンターテイメント分野での活動も積極的に行い、映画「劇場版おっさんずラブ」やドラマ、DIR EN GREY「落ちた事のある空」他多数MV出演。2021年Re:birth FestivalでのテクノDJとの共演、同年及び2022年MUTEK出演、ファッションブランドのモデルなど活動は多岐に渡り、舞踏の認知拡大・次世代の舞踏の提示に努める。",
      },
      {
        num: "pop-up5",
        img: require("@/assets/img/artist05.webp"),
        name: "小出稚子",
        job: "音楽",
        content:
          "東京音楽大学、アムステルダム音楽院、デン・ハーグ王立音楽院修了。インドネシア国立芸術大学スラカルタ校でジャワガムランを学ぶ。第17回芥川作曲賞、第76回日本音楽コンクール作曲部門第2位と聴衆賞、第18回出光音楽賞、アリオン賞等受賞。2016年トンヨン国際音楽祭では、アジア作曲家ショーケース・ゲーテ賞ならびに聴衆賞を受賞。作品は国内外のオーケストラやアンサンブル等によって演奏されている。2022年にはBBC Radio 3の委嘱による新作《揺籠と糸引き雨》がBBC交響楽団により世界初演された。2023年名古屋フィルハーモニー交響楽団第4代コンポーザー・イン・レジデンスに就任。",
      },
      {
        num: "pop-up6",
        img: require("@/assets/img/artist06.webp"),
        name: "輿一朗",
        job: "ダンス",
        content:
          "新潟出身。振付作家・ダンスアーティスト・DANCE PJ REVO主宰。横浜を拠点にパフォーマンス作品を制作・上演をして活動中。EXPERIMENTAL HIPHOPという独自のクリエイティブテーマを掲げている。海外6ヵ国からの招へい上演歴を持つ。国内コンペティションでも受賞歴多数。もの(object)と身体を組み合わせて表現に変換することで、マテリアルとの対話を目指している。<br />またワークショップの企画運営や、福祉や保育現場にも携わり、ダンス活動を展開している。近年では「祭り」×「クリエーションダンス」を世界へ発信していく試み。",
      },
      {
        num: "pop-up7",
        img: require("@/assets/img/artist07.webp"),
        name: "越ちひろ",
        job: "絵画",
        content:
          "2004年トーキョーワンダーウォール賞受賞後、東京都庁での個展を機に数々の展覧会を開催。2008年活動拠点を長野に移す。<br />2016年上田市立美術館にて個展「ワンダーワンダー」開催。2018年「アート界の芥川賞」とも称されるVOCA展(上野の森美術館)に出品。スリランカでのJAPAN EXPOにて公開制作。2019年千曲市主催の個展「ミライノ色 ミライノ光 ーまちじゅうが美術館ー」開催。2022年長野善光寺御開帳にてコラボ御朱印と御守りを発表開始。<br />2023年バングラデシュスラム街壁画制作。2024年パラオの小学校に壁画完成。2022年〜別所芸術祭出演。2023年別所温泉アンバサダー就任。",
      },
      {
        num: "pop-up8",
        img: require("@/assets/img/artist08.webp"),
        name: "寺村サチコ",
        job: "インスタレーション",
        content:
          "絹に絞り染めと型染めの技法で染色し、立体造形作品やインスタレーション作品へと展開して自身の表現活動を行なっています。作品のテーマは女性や生命の美しさとその裏側にあるドロドロとした部分、また、それらの感情や感覚のありかを探しています。モチーフはクラゲや有毒植物など、美しいだけではない二面性を持つものに着目し制作を続けています。",
      },
      {
        num: "pop-up9",
        img: require("@/assets/img/artist09.webp"),
        name: "NEphRiTE dance company",
        job: "ダンス",
        content:
          "2017-NEphRiTE dance company設立。文化庁新進芸術家研修員として2年間ベルリンで活動ヨーロッパへも活動範囲を広げ2020より新潟市秋葉区の里山を生活拠点に選び移住。現代舞踊協会制定新人賞奨励賞（藍歌）,World dance competition in Niigataにおいて新潟県知事賞受賞。SAI DANCE FESTIVAL2024COMPETITIONソロ部門にて優秀作品賞/First Prize受賞（貴好）。ドイツ,オランダ,エストニア,リトアニア,のダンスフェスバルへ招聘。帰国後、創作活動の傍ら、アートと地域をつなげるクリエーションチームあるてぃすとを設立。里山のある新潟市秋葉区を拠点に地域の子供たちとの創作活動も始める。2023年新潟市秋葉区に泊まれる劇場スロウプハウス（ゲストハウス）をオープン。アーティスト・イン・レジデンス受入れの取り組みを開始。",
      },
      {
        num: "pop-up10",
        img: require("@/assets/img/artist10.webp"),
        name: "船木こころ",
        job: "ダンス",
        content:
          '玉川大学芸術学部卒業。文化庁主催公演新人賞。文部科学大臣賞。全国舞踊コンクール等第1位多数受賞。<br />Outstanding Contemporary Choreographer(アメリカ)。<br />THE DANCE TIMES月間ベストテン選出。<br />文化庁新進芸術家海外研修員としてオランダにて研修。<br />新国立劇場主催公演にて主演ソリストを担うなど多彩な国内外の振付家の作品に出演。<br />B\'z稲葉浩志、Dragon Ash、カノエラナMV出演、ポルノグラフィティジャケットモデル、FUJIFILM化粧品"ASTALIFT"アジア向けPVなどに単独アーティストダンサーとして起用されるなど多様な活動をしている。',
      },
      {
        num: "pop-up11",
        img: require("@/assets/img/artist11.webp"),
        name: "manimanium",
        job: "写真",
        content:
          "大阪出身。15歳の頃より写真を独学で始める。SNSを通して写真を発表するうちに撮影依頼が舞い込み、生業に。2022年より東京に拠点を移す。電子写真集「#Escape」やファッションブランドの撮影など、幅広いジャンルで活動する。",
      },
      {
        num: "pop-up12",
        img: require("@/assets/img/artist12.webp"),
        name: "山之口理香子",
        job: "ダンス",
        content:
          "多摩美術大学演劇舞踊デザイン学科卒業。<br />文化庁制定新人賞、全国舞踊コンクール現代舞踊第2位、ヨコハマコンペティション第1位、埼玉全国舞踊コンクール第2位、座間全国舞踊コンクール第1位など全国規模のコンクールにて幼い頃より様々な賞を受賞。<br />近年では劇作・演出家根本宗子の作品に振付出演の他、様々なアイドルグループの振付も行っている。また自身が着用するゴシック＆ロリータブランドのイベント等多岐に渡り活躍している。",
      },
      {
        num: "pop-up13",
        img: require("@/assets/img/artist14.webp"),
        name: "米澤一平",
        job: "タップダンス",
        content:
          "1989年東京生まれ。タップダンサー。自分とは異なる他の存在や知らない/新しい環境や関係がひとに及ぼす影響に興味を持ち表現方法の異なる他者との対話、共存の可能性を表現者/鑑賞者を巻き込んだ場作りをする。2017年以降600回以上の本番を行い喫茶、BAR、お寺、商業施設、古民家、空き地など様々な空間と異ジャンルのアーティストを出会わせ起こる場と対峙する。主催企画「In The Zone」vol.85「FOOTPRINTS」vol.59「横須賀のsalo」vol.8「サーカスBar野毛うっふsession」vol.80「ARTERA」vol.6「ノボリトリート」vol.28「kOe」vol.4等。",
      },
      {
        num: "pop-up14",
        img: require("@/assets/img/artist15.webp"),
        name: "ワカバコーヒー",
        job: "舞踏",
        content:
          "元大駱駝艦の舞踏家・若羽幸平とダンサー川村真奈によるユニット。さまざまな表現者たちが、喫茶店のように自由に出入りする空間を目指してプロジェクトを展開している。代表作「なにものにもなれなかったものたちへ」はSAI DANCE FESにてDuo 1st prize等、さまざまな賞を受賞、国内外より多数の招聘を受け、Contemporary Butoh と評される。<br />2023年、孤高の舞踏家、吉本大輔をゲストに迎え「おどらない、からだ」を制作「高架下空き倉庫」にて公演、好評を得る。自伝美術が「おちらしさんアワード 2023」にて7位受賞。月に一度舞踏ワークショップを開催中。",
      },
    ]);
    const pcSize = 3;
    const pcArtists = computed(() => {
      const result = [];
      for (let i = 0; i < artists.value.length; i += pcSize) {
        result.push(artists.value.slice(i, i + pcSize));
      }
      return result;
    });
    const spSize = 2;
    const spArtists = computed(() => {
      const result = [];
      for (let i = 0; i < artists.value.length; i += spSize) {
        result.push(artists.value.slice(i, i + spSize));
      }
      return result;
    });
    return {
      artists,
      pcArtists,
      spArtists,
    };
  },
};
</script>

<style lang="scss" scoped>
.artist-list {
  position: relative;
  margin-bottom: 0;
  padding-bottom: 8rem;
  background: #f7f7f7;
  .title {
    @include sp {
      margin-bottom: 0;
    }
  }
}

.container {
  z-index: 1;
}

.artist {
  background-color: white;
  margin-bottom: 8rem;
  text-align: left;
  border: 2px solid #000;
}

.artist-text {
  padding: 1.5rem;
  h3 {
    font-weight: 600;
    margin-bottom: 0;
  }
}

.sp {
  position: relative;
  z-index: 1;
}

.sp-artist-text {
  @include sp {
    text-align: center;
    margin-bottom: 4rem;
  }
}

.c {
  text-align: center;
  img {
    border: 1px solid #000;
  }
}

.overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.5s 0s ease;
  visibility: hidden;
  opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@for $i from 1 through 14 {
  #pop-up#{$i} {
    display: none;
  }

  #pop-up#{$i}:checked + .overlay {
    visibility: visible;
    opacity: 1;
    z-index: 100000;
    background-color: #000000d0;
    width: 100%;
  }
}

.popWindow {
  border-radius: 5px;
  overflow: auto;
  max-height: 95%;
  min-width: fit-content;
  max-width: 95%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100010;
  font-size: 13px;
  line-height: 18px;
  margin: 10px auto;
  padding: 4px 10px;
  background: #fffd;
  text-align: center;
  transition: all 500ms ease-in-out;
}

.popWindow .ptitle {
  font-size: 15px;
  margin: 8px;
  color: #333;
}

.popWindow .close {
  position: absolute;
  top: 8px;
  right: 10px;
  transition: all 300ms;
  font-size: 24px;
  line-height: inherit;
  font-weight: bold;
  text-decoration: none;
  color: #000;
  padding: 2px;
}

.popWindow .close:hover {
  color: #06d85f;
}

.popWindow::-webkit-scrollbar-track {
  background: none;
}

.popWindow::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.popWindow::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 5px;
}

.clickable:hover {
  cursor: pointer;
}

.c p {
  margin-bottom: 2rem;
  font-size: 11px;
}
.sp-artist .c .artist-name {
  margin-bottom: 0.25em;
  line-height: 1;
  font-size: 16px;
}
.program-name {
  margin-bottom: 0;
}

.sp-flex {
  @include sp {
    display: flex;
    justify-content: flex-start;
  }
}

.sp-artist {
  width: 50%;
}
</style>

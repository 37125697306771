<template>
  <section id="section6" class="map gray">
    <h2 class="center title">イベントマップ</h2>
    <div class="container center">
      <div class="img-contaniner">
        <figure class="img-wrapper">
          <img
            src="@/assets/img/map-eventList.svg"
            alt="マップ用イベント情報"
          />
        </figure>
        <figure class="img-wrapper">
          <img src="@/assets/img/map.svg" alt="マップ" />
        </figure>
      </div>
    </div>
    <p class="map-note">
      ※1&emsp;9月8日(日)にお車でご来場の方はイベント専用駐車場をご利用ください。<br />※2&emsp;別所温泉駅でTicketQRを使用した乗降に限り運賃が無料になります。
    </p>
  </section>
</template>

<script>
export default {
  name: "EventMap",
};
</script>

<style lang="scss" scoped>
.map {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 8rem;
  &.gray {
    background-color: #f7f7f7;
  }
}

.map .container {
  width: #{"min(90%, 1200px)"};
  margin: 0 auto;
  margin-bottom: 20px;
  @include sp {
    padding: 0rem;
  }
}

.img-contaniner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include sp {
    flex-direction: column;
    row-gap: 20px;
  }
}

.img-wrapper {
  flex: 1;
  width: 50%;
  @include sp {
    margin: 0 4vw;
    width: 100%;
  }
  > img {
    width: 100%;
    height: auto;
  }
}

.map-pc {
  display: inline;
  @include width($width-500) {
    display: none;
  }
}
.map-sp {
  display: none;
  @include width($width-500) {
    display: inline;
  }
}
.map-note {
  width: fit-content;
  max-width: 90%;
  margin: 0 auto;
  padding: 2rem 2rem;
  font-size: 0.8em;
  color: #fff;
  background: #1f1f1f;
}
</style>

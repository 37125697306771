<template>
  <a class="form" :href="link" target="_blank" rel="noopener noreferrer"
    ><slot name="txtLink">申込はこちらから ></slot></a
  >
</template>

<script>
export default {
  name: "BaseReserveBtn",
  props: {
    mainEvent: Boolean,
    link: String,
    txtLink: String,
  },
};
</script>

<style lang="scss" scoped>
.form {
  position: relative;
  display: block;
  width: fit-content;
  margin: 0 auto;
  margin-top: 2em;
  padding: calc(1.5rem - 12px) 3rem 1.5rem;
  line-height: 1.5;
  font-size: 1.6rem;
  font-weight: 500;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: #000;
  background: #feea0f;
  &::before {
    position: absolute;
    top: -6px;
    left: -6px;
    content: "";
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border: 2px solid #000;
  }
  &:hover {
    padding: calc(1.5rem - 6px) 3rem;
    color: #000;
    &::before {
      top: 0;
      left: 0;
    }
  }
}
</style>

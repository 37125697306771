<template>
  <section id="section5" class="ticket">
    <h2 class="center title">各イベントチケット</h2>
    <div class="container">
      <div class="ticket-item-wrapper">
        <div
          v-for="ticketItem in ticketItems"
          :key="ticketItem.id"
          class="ticket-item"
        >
          <p class="ticket-item-ttl">{{ ticketItem.ttl }}</p>
          <p class="ticket-item-txt" v-html="ticketItem.txt"></p>
          <BaseReserveBtn v-if="ticketItem.link" :link="ticketItem.link"
            ><template v-if="ticketItem.txtLink" v-slot:txtLink>{{
              ticketItem.txtLink
            }}</template></BaseReserveBtn
          >
        </div>
      </div>
    </div>
    <div class="ticket-sub">
      <p>
        ※小学生以下は無料。保護者同伴でお願いいたします。<br />
        ※チケットは上記購入ボタンよりご購入いただけます。<br />
        ※お客様都合によるチケットの払い戻しは致しかねますので、予めご了承ください。<br />
        ※当日券はイベント会場にて現金のみの対応とさせていただきます。<br />
        (定員に達し次第事前もしくは当日に締め切る場合があります)
      </p>
    </div>
  </section>
</template>

<script>
import BaseReserveBtn from "@/components/base/BaseReserveBtn.vue";
import { ref } from "vue";
export default {
  name: "EventTicket",
  components: {
    BaseReserveBtn,
  },
  setup() {
    const ticketItems = ref([
      {
        id: 1,
        ttl: "メインパフォーマンス",
        txt: "1部 オーパ♪キャマラード合奏団「みんなで楽しいわくわくコンサート」<br />2部 「From ZERO -beyond-」",
        link: "https://teket.jp/10494/36490",
      },
      {
        id: 2,
        ttl: "ワカバコーヒー with 米澤一平「あくまのしょうめい」",
        txt: "一般：2,500円<br />サブスク会員：1,500円(ワンドリンク、軽食あり)",
        link: "https://teket.jp/10494/36641",
      },
      {
        id: 3,
        ttl: "朝活ワークショップ ～山之口理香子✕manimanium「color」編～",
        txt: "受講料：2,000円",
        link: "https://teket.jp/10494/36642",
      },
      {
        id: 4,
        ttl: "常楽寺で繭クラフトを楽しもう！ワークショップ",
        txt: "受講料：500円<br />お問い合わせ：0268-37-1234(常楽寺)",
        link: "tel:0268371234",
        txtLink: "申込はお電話から >",
      },
      {
        id: 5,
        ttl: "朝活ワークショップ ～カナキティ「Bubble」編～",
        txt: "受講料：2,000円",
        link: "https://teket.jp/10494/36646",
      },
      {
        id: 6,
        ttl: "上田電鉄✕別所温泉芸術祭コラボレーションプログラム①「君が行く道の長手を繰り畳ね..」",
        txt: "一般：前売2,500円、当日2,800円<br />中高生：前売1,500円、当日1,800円<br />小学生以下：無料(保護者同伴でお願いいたします。)",
        link: "https://teket.jp/10494/36643",
      },
      {
        id: 7,
        ttl: "上田電鉄✕別所温泉芸術祭コラボレーションプログラム②信州プロレスリング「別所線電車プロレス！」",
        txt: "乗車無料<br />限定40席(7月15日の長野市芸術館大会にて20席配布。残り20席はwebサイトにて8月15日の23時59分までエントリー受付生配信抽選会行います！)",
        link: "https://swfnagano.com/",
        txtLink: "申込は公式HPから >",
      },
    ]);
    return {
      ticketItems,
    };
  },
};
</script>

<style lang="scss" scoped>
.ticket {
  margin-top: 0;
  margin-bottom: 0;
}
.container {
  @include sp {
    padding: 0;
    width: calc(100% - 4vw);
  }
}

.ticket-item-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  gap: 2vw;
}

.ticket-item {
  position: relative;
  width: calc(100% / 3 - 4vw / 3);
  margin: 0;
  padding: 0.5em;
  border: 2px solid #000;
  @include sp {
    width: calc(50% - 1vw);
  }
  @include width($width-500) {
    width: 100%;
  }
}

.ticket-item-ttl {
  display: -webkit-box;
  font-size: 2rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.ticket-item-txt {
  margin-bottom: 120px;
}

:deep(.form) {
  position: absolute;
  left: 50%;
  bottom: 20px;
  width: 15em;
  max-width: 90%;
  transform: translateX(-50%);
}

.ticket-sub {
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: #{"min(90%, 800px)"};
  margin: 0 auto;
  p {
    font-size: 0.8em;
    text-align: left;
  }
}
</style>

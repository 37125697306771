<template>
  <section id="section9" class="archive">
    <h2 class="center title">アーカイブ</h2>
    <div
      v-for="bannerItem in sortedBannerItems"
      :key="bannerItem.id"
      class="archive-banner-wrapper"
    >
      <a class="archive-banner" :href="bannerItem.link" target="_blank">
        <img :src="bannerItem.img" :alt="bannerItem.alt" />
      </a>
      <p class="archive-text" v-html="bannerItem.txt"></p>
    </div>
  </section>
</template>

<script>
import { ref, computed } from "vue";
export default {
  name: "ArchiveFes",
  setup() {
    const bannerItems = ref([
      {
        id: 1,
        link: "https://be-fes.bessho-onsen.com/archive/",
        img: require("@/assets/img/banner1.webp"),
        alt: "別所温泉芸術祭-ZERO-",
        txt: "【2022年開催】別所温泉芸術祭<span>-ZERO-</span>",
      },
      {
        id: 2,
        link: "https://be-fes.bessho-onsen.com/archive/2/",
        img: require("@/assets/img/banner2.webp"),
        alt: "別所温泉芸術祭-ZERO-2",
        txt: "【2023年開催】別所温泉芸術祭<span>-ZERO-2</span>",
      },
    ]);
    const sortedBannerItems = computed(() => {
      return [...bannerItems.value].sort((a, b) => b.id - a.id);
    });
    return {
      sortedBannerItems,
    };
  },
};
</script>

<style lang="scss">
.archive {
  background-color: #f7f7f7;
  margin-bottom: 0;
  padding-bottom: 8rem;
}
.archive-banner-wrapper {
  width: #{"min(80%, 600px)"};
  margin: 0 auto;
  &:first-of-type {
    margin-bottom: 60px;
  }
}
.archive-banner {
  position: relative;
  display: block;
  margin-bottom: 0.5rem;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &:hover::after {
    background: rgba(#fff, 0.2);
  }
  > img {
    width: 100%;
    height: auto;
    vertical-align: bottom;
  }
}

.archive-text {
  font-size: 1.8rem;
  font-weight: 500;
  > span {
    display: inline-block;
  }
}
</style>

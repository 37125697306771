<template>
  <div class="sliderimg">
    <swiper-container
      class="swiper"
      :slides-per-view="1"
      :autoplay="true"
      :speed="1500"
      :loop="true"
      :effect="'fade'"
    >
      <swiper-slide
        v-for="sliderItem in sliderItems"
        :key="sliderItem.id"
        class="swiper-item"
      >
        <figure class="swiper-img">
          <img :src="sliderItem.img" alt="スライダー画像" />
        </figure>
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<script>
import { ref } from "vue";
import { register } from "swiper/element/bundle";
register();
export default {
  name: "SliderImg",
  setup() {
    const sliderItems = ref([
      {
        id: 1,
        img: require("@/assets/img/slider01.webp"),
      },
      {
        id: 2,
        img: require("@/assets/img/slider02.webp"),
      },
      {
        id: 1,
        img: require("@/assets/img/slider03.webp"),
      },
    ]);
    return {
      sliderItems,
    };
  },
};
</script>

<style lang="scss" scoped>
.swiper-img {
  position: relative;
  width: 100%;
  padding-bottom: 35%;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.4);
  }
  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>

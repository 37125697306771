<template>
  <div class="topvisual">
    <div class="topvisual-inner">
      <video
        class="movie"
        poster="@/assets/img/befes3-movie-poster.png"
        preload="metadata"
        autoplay
        muted
        loop
        playsinline
        webkit-playsinline
      >
        <source src="@/assets/video/befes3-movie.webm" type="video/webm" />
        <source src="@/assets/video/befes3-movie.mp4" type="video/mp4" />
      </video>
      <figure class="mainimg">
        <img src="@/assets/img/main.svg" alt="トップビジュアルのロゴ" />
      </figure>
      <figure class="date">
        <img
          src="@/assets/img/date.svg"
          alt="2024年から9月5日から9月8日に開催"
        />
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopVisual",
};
</script>

<style lang="scss" scoped>
.topvisual {
  width: 100%;
  height: 100svh;
}

.topvisual-inner {
  width: 100%;
  height: 100%;
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.7);
  }
}

.movie {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mainimg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50vw;
  transform: translate(-50%, -50%);
  @include sp {
    width: 80vw;
  }
  > img {
    width: 100%;
    height: auto;
  }
}

.date {
  position: absolute;
  left: 1vw;
  bottom: 1vh;
  width: 30vw;
  @include sp {
    width: 50vw;
  }
  > img {
    width: 100%;
    height: auto;
  }
}
</style>

<template>
  <section id="section8" class="organizer">
    <h2 class="center title">運営</h2>
    <div class="container row flow">
      <div class="col span-3 organizer-img">
        <img src="@/assets/img/organize01.webp" alt="" />
      </div>
      <div class="col span-9">
        <p>
          アートディレクター：山本 裕<br />
          企画運営／広報：株式会社Yourbility Developers<br />
          宣伝美術：陶山ゆつき<br />
          記録写真：manimanium<br />
          記録映像、PR動画：Hiroki Konno、Ali Yokota<br />
          <br />
          主催：Be-FES.Lab.（別所温泉芸術祭実行委員会）<br />
          共催：別所温泉自治会連合会<br />
          協力：別所温泉観光協会、別所温泉旅館組合、上田電鉄株式会社、別所がぁる(上田女子短期大学)、信州プロレスリング、一般社団法人パフォーミングアーツ協会
          <br /><br />
          お問い合わせ<br />
          Tel：0268-38-2020（別所温泉旅館組合内）<br />
          Mail：bessho-fes@ydeps.co.jp<br />
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "OrganizerCredit",
};
</script>

<style lang="scss" scoped>
.organizer {
  margin-top: 0;
  @include sp {
    padding: 0 2rem;
  }
}
@include sp {
  height: 300px;
}
.row .col.span-3 {
  flex: 0 0 50%;
  max-width: 75%;
  margin-bottom: 8rem;
}
.organizer .container .organizer-img {
  margin-bottom: 0rem;
  > img {
    border: 2px solid #000;
  }
  @include sp {
    max-width: 100%;
  }
}
.row .col.span-9 {
  flex: 0 0 46%;
  max-width: 74%;
  @include sp {
    max-width: 100%;
  }
}
</style>

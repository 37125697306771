<template>
  <section id="section4" class="schedule gray">
    <h2 class="center title">スケジュール</h2>
    <div class="container center pc">
      <img class="white" src="@/assets/img/schedule.svg" alt="スケジュール" />
    </div>
    <div class="container center sp">
      <img
        class="white"
        src="@/assets/img/schedule_sp.svg"
        alt="スケジュール"
      />
    </div>
    <div class="container center gray schedule-text">
      <p>
        ※各イベントは混雑状況や定員に達し次第締め切る場合がございます。また、やむを得ない事情により出演者や演目が変更になる場合がございますのでご了承ください。
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "ScheduleList",
};
</script>

<style lang="scss" scoped>
.schedule {
  padding-bottom: 0;
  margin-bottom: 0;
  .sp {
    @include sp {
      padding: 0 2vw;
    }
  }
  p {
    text-align: left;
  }
}
.gray {
  margin-top: 0;
  background-color: #f7f7f7;
}
.white {
  width: #{"min(90%, 1200px)"};
  margin: 0 auto;
  margin-bottom: 2rem;
  @include sp {
    width: 100%;
  }
}
.schedule-text {
  padding: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: #{"min(90%, 800px)"};
  margin: 0 auto;
  margin-bottom: 0;
  font-size: 0.8em;
  @include sp {
    width: 100%;
    padding-top: 0rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .schedule .sp {
    padding-top: 0rem;
    padding-left: 2rem;
  }
}
</style>
